<template>
  <div class="keyword-block">
    <menu-breadcrumb>
      <el-breadcrumb-item>關鍵字管理</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <table-title>
        <p>
          關鍵字列表 <span>數量：{{ total }}</span>
        </p>

        <el-button type="success" @click="visibility.create = true"
          >新增關鍵字</el-button
        >
      </table-title>

      <el-table :data="keyword_data">
        <el-table-column
          label="ID"
          prop="keyword_id"
          sortable
        ></el-table-column>
        <el-table-column
          label="中文名稱"
          prop="name_zh"
          sortable
        ></el-table-column>
        <el-table-column
          label="英文名稱"
          prop="name_en"
          sortable
        ></el-table-column>
        <el-table-column label="編輯" fixed="right" width="180">
          <template #header="{}">
            <el-input
              v-model="setup.search"
              size="mini"
              placeholder="搜尋關鍵字"
              @keyup.enter.native="handleSearch"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch"
              ></el-button>
            </el-input>
          </template>
          <template #default="{ row }">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="handleEdit(row)"
              >編輯</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(row.keyword_id)"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>

    <create-keyword-dialog
      v-if="visibility.create"
      :visibility="visibility.create"
      @update:visibility="visibility.create = $event"
      @get:keywords="handleGetKeywords"
    ></create-keyword-dialog>

    <edit-keyword-dialog
      v-if="visibility.edit && edit_keyword_data !== null"
      :visibility="visibility.edit"
      :keyword="edit_keyword_data"
      @update:visibility="visibility.edit = $event"
      @get:keywords="handleGetKeywords"
    ></edit-keyword-dialog>
  </div>
</template>

<script>
import CreateKeywordDialog from "./components/CreateKeywordDialog.vue";
import EditKeywordDialog from "./components/EditKeywordDialog.vue";
import { deleteKeyword, getKeywordsPagination } from "@/api/keyword";

export default {
  name: "Keyword",
  components: { CreateKeywordDialog, EditKeywordDialog },
  data() {
    return {
      keyword_data: [],
      edit_keyword_data: null,
      total: 0,
      setup: {
        page: 0,
        limit: 25,
        search: "",
      },
      visibility: {
        create: false,
        edit: false,
      },
    };
  },
  created() {
    this.handleGetKeywords();
  },
  methods: {
    async handleGetKeywords() {
      const { results, total } = await getKeywordsPagination(this.setup);

      this.keyword_data = results;
      this.total = total;
    },
    handleSearch() {
      this.setup.page = 0;

      this.handleGetKeywords();
    },
    handleChangePage(page) {
      this.setup.page = page;

      this.handleGetKeywords();
    },
    handleEdit(keyword) {
      this.edit_keyword_data = keyword;
      this.visibility.edit = true;
    },
    handleDelete(id) {
      this.$confirm("是否刪除該關鍵字", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteKeyword(id);

          this.$message({
            type: "success",
            message: "刪除成功",
          });

          this.handleGetKeywords();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
