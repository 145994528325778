<template>
  <el-dialog
    title="編輯關鍵字"
    :visible.sync="visibility"
    width="400px"
    :before-close="handleClose"
  >
    <el-form ref="formRef" :model="keyword_form" :rules="rules">
      <el-form-item label="中文名稱" prop="name_zh">
        <el-input
          v-model="keyword_form.name_zh"
          maxlength="128"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="英文名稱" prop="name_en">
        <el-input
          v-model="keyword_form.name_en"
          maxlength="128"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="warning" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit">確認</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updateKeyword } from "@/api/keyword";

export default {
  name: "EditKeywordDialog",
  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    keyword: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      keyword_form: {
        name_zh: "",
        name_en: "",
      },
      rules: {
        name_zh: [
          { required: true, message: "請輸入中文名稱", trigger: "blur" },
          { max: 128, message: "中文名稱最多128個字", trigger: "blur" },
        ],
        name_en: [
          { required: true, message: "請輸入英文名稱", trigger: "blur" },
          { max: 128, message: "英文名稱最多128個字", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    const cloneKeyword = JSON.parse(JSON.stringify(this.keyword));

    this.keyword_form.name_en = cloneKeyword.name_en;
    this.keyword_form.name_zh = cloneKeyword.name_zh;
  },
  methods: {
    handleClose() {
      this.$emit("update:visibility", false);
    },
    handleSubmit() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          await updateKeyword(this.keyword.keyword_id, this.keyword_form);

          this.$message({
            message: "編輯成功",
            type: "success",
          });

          this.$emit("get:keywords");
          this.handleClose();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
